import { Helmet } from "react-helmet"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

import Footer from "components/Footer"
import { PageTopAnchor } from "components/PageTopAnchor"
import { PcFooterLink } from "components/FooterLink/Pc"
import { MoFooterLink } from "components/FooterLink/Mo"
import { MoFooterWrap } from "components/FooterWrap"
import { PhoneArea } from "components/PhoneArea"
import { BannerArea } from "components/BannerArea"
import { Search } from "components/GoogleCustomSearch"
import ClientOnly from "hooks/ClientOnly"

const SearchPage = () => {
  const { t } = useTranslation()

  return (
    <main>
      <Helmet title={t("サイト内検索", "サイト内検索")} />
      <div className="sub_page">
        <section className="page_header service inviewfadeInUp">
          <div className="container2">
            <h1>{t("サイト内検索", "サイト内検索")}</h1>
          </div>
        </section>

        <div className="container2">
          <section className="breadcrumb">
            <ul>
              <li>
                <Link to="/">{t("星井眼科医院TOP", "星井眼科医院TOP")}</Link>
              </li>
              <li>{t("サイト内検索", "サイト内検索")}</li>
            </ul>
          </section>

          <section className="content">
            <div className="main_area search inviewfadeInUp fadeInUp">
              <div>
                <p>キーワードを入力するとサイト内を検索することができます</p>
                <ClientOnly>
                  <Search resultClass="gcse-searchbox-only" />
                  <Search resultClass="gcse-searchresults-only" />
                </ClientOnly>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className="container2">
        <section className="content">
          <PhoneArea />
          <BannerArea />
        </section>
      </div>

      <section className="content">
        <div className="footer_link inviewfadeInUp">
          <PcFooterLink />
          <MoFooterLink />
        </div>
      </section>

      <Footer />
      <PageTopAnchor />
      <MoFooterWrap />
    </main>
  )
}

export default SearchPage
